@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: 'GTWalsheimPro';
  src: url('./assets/fonts/GTWalsheimPro-Black.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'GTWalsheimPro';
  src: url('./assets/fonts/GTWalsheimPro-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'GTWalsheimPro';
  src: url('./assets/fonts/GTWalsheimPro-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'GTWalsheimPro';
  src: url('./assets/fonts/GTWalsheimPro-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'SegoeUIEmoji';
  src: url('./assets/fonts/segoe-ui-emoji.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope.ttf') format('truetype');
  font-weight: 400;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: Inter;
}

::-webkit-scrollbar {
  height: 10px;
  width: 4px;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background: var(--stroke-test, #1a202f);
}

::-webkit-scrollbar-thumb {
  background: var(--linear, linear-gradient(217deg, #e250e5 0%, #4b50e6 100%));
  border-radius: 12px;
}

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
}

.popup-select {
  background: #23202c !important;
  min-width: 288px !important;
  border-radius: 8px !important;
  padding: 12px !important;
  width: 300px !important;
  /* right: 180px !important;
  left: 0 !important; */
}

@media screen and (max-width: 450px) {
  .popup-select {
    width: 70px !important;
    min-width: 70px !important;
    font-size: 12px !important;
  }
}

.popup-select .rc-virtual-list-holder-inner {
  /* padding: 12px; */
}
.popup-select .ant-select-item {
  padding: 8px 16px;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
}
.popup-select .option-select {
  background: #23202c !important;
  .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    align-items: center !important;
  }
}
.ant-select-dropdown {
  padding: 0 !important;
}
.popup-select .popup-select-default {
  background: #23202c !important;
  border-radius: 8px !important;
  padding: 12px !important;
  left: 305px !important;
}

.popup-select .option-select-default {
  background: #23202c !important;
}

.popup-select .option-select-default:hover {
  background: var(
    --linear,
    linear-gradient(
      217deg,
      rgba(226, 80, 229, 0.3) 0%,
      rgba(75, 80, 230, 0.3) 100%
    )
  ) !important;
  border-radius: 4px !important;
}

.popup-select
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  font-weight: 600;
}

.Toastify__toast-container {
  width: 400px !important;
  z-index: 10001 !important;
}
.Toastify__toast--error {
  text-align: center;
  /* height: 48px; */
  /* border-radius: 8px !important;
  background: #d0302f !important; */
  border-radius: 8px !important;
  /*border: 1px solid #ffccc7 !important;*/
  background: #282843 !important;
}
.Toastify__toast--error::after {
  /*content : url('../assets/images/svg/closeToast.svg');*/
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
  z-index: 100000;
}
/* .Toastify__toast--error::before {
  content: url('./assets/icons/icon_error.svg') !important;
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 9px;
} */
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  height: 48px;
}

.Toastify__toast--success {
  height: 48px;
  border-radius: 8px !important;
  background: #282843 !important;
}
.Toastify__toast--success::after {
  /*content : url('../assets/images/svg/closeToast.svg');*/
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--success::before {
  /*content: url('./assets/icons/icon_success.svg') !important;*/
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  height: 48px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    top: 20px !important;
    width: 90vw !important;
    left: 5vw !important;
  }

  .ant-select-item-empty {
    display: none !important;
  }
}

:root {
  --toastify-toast-min-height: 48px;
  --toastify-toast-width: auto;
}

/* .ant-select-item-option {
  background: #23202C;
}

.ant-select-item-option-active   {
  background: var(--linear, linear-gradient(217deg, #E250E5 0%, #4B50E6 100%));
}

.ant-select-dropdown {
  padding: 0 !important;
} */

.ant-select-dropdown {
  background: var(--background-dark-theme-bg-on-dark, #18151c) !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border: 1px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
}

.ant-select-dropdown * {
  color: white !important;
}

.ant-select-item-option-active * {
  color: #ffffff !important;
}

.ant-select-item-option-selected * {
  color: #ffffff !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active {
  background-color: #23202c !important;
}

.ant-modal-wrap,
.ant-modal-mask {
  z-index: 1000 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-content {
  transform: translateX(-10px) !important;
}
.popup-autocomplete {
  background: #23202c !important;
  padding: 0 !important;
  border-radius: 12px !important;
  .ant-select-item-option-content {
    padding: 12px;
    border-bottom: #3c404a 1px solid !important;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    align-items: center;
    width: 100%;
  }
  .ant-select-dropdown {
    margin: 0 !important;
  }
  .ant-select-dropdown {
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 30px !important;
  }

  .ant-select-item,
  .ant-select-item-empty {
    padding: 0 !important;
  }
}

.bp4-portal {
  z-index: 10000 !important;
}

.ant-select-dropdown {
  .rc-virtual-list-scrollbar {
    width: 4px !important;
  }
  .rc-virtual-list-scrollbar-thumb {
    background: var(
      --linear,
      linear-gradient(217deg, #e250e5 0%, #4b50e6 100%)
    ) !important;
  }
}

.ant-picker-range-wrapper .ant-picker-panel-container {
  background: transparent !important;
  border-radius: 10px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #e250e5 !important;
  /* border: none !important; */
}
.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #3f224b !important;
}

.ant-picker-panels {
  background-color: #161624;
  color: white !important;
  border-radius: 10px !important;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):before {
  background: #3f224b !important;
}
.ant-picker-panels * {
  color: white !important;
}
.ant-picker-header {
  border-bottom: none !important;
}
.ant-picker-panel {
  border: none !important;
}
.ant-picker-cell:hover * {
  color: black !important;
}
.ant-picker-cell.ant-picker-cell-range-hover-edge-start-near-range:hover * {
  color: white !important;
}
.ant-picker-cell.ant-picker-cell-range-hover-edge-end-near-range:hover * {
  color: white !important;
}
.ant-picker-cell-range-hover-end:hover * {
  color: white !important;
}
.ant-picker-cell-range-hover-start:hover * {
  color: white !important;
}
.ant-picker-cell-start.ant-picker-cell-range-hover-end:hover * {
  color: black !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: none !important;
}
.ant-picker-range-arrow:before {
  background: transparent !important;
}

@media screen and (max-width: 768px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .ant-picker-dropdown-range {
    top: 310px !important;
  }
}

/* css for date picker in schedule post*/
.ant-picker-dropdown .ant-picker-panel-container {
  background-color: #27232d;
  border-radius: 10px !important;
}

.ant-picker-dropdown .ant-picker-panel-container * {
  color: white !important;
}
.ant-picker-time-panel-cell .ant-picker-time-panel-cell-selected * {
  color: black !important;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #e250e5 !important;
}
.ant-picker-time-panel-cell *:hover {
  color: black !important;
}

.ant-picker-datetime-panel .ant-picker-cell:hover * {
  color: black !important;
}

.ant-picker-dropdown .ant-picker-panel-container * {
  border-color: transparent !important;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-ok button {
  background: linear-gradient(217deg, #e250e5 0%, #4b50e6 100%) !important;
  border: none !important;
  width: 100px;
  border-radius: 6px;
  height: 30px;
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-ok
  button:disabled {
  filter: brightness(0.6);
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-cell-disabled * {
  filter: brightness(0.5);
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-disabled
  * {
  filter: brightness(0.5);
}

em-emoji-picker:focus {
  position: relative;
}
em-emoji-picker .option:hover {
  background: gray !important;
  color: white !important;
}

.option:hover {
  background: gray !important;
  color: white !important;
}

.ant-avatar.picture-page {
  width: 28px !important;
  height: 28px !important;
  margin-right: 6px;
}

.ant-menu-submenu-popup {
  padding-left: 8px !important;
  width: 199px;
}

.ant-menu-submenu-popup > .ant-menu-vertical {
  border-radius: 12px !important;
  border-right: 1px solid var(--outline-stroke, #1c1c2c) !important;
  background: var(--surface-bg-2, #1c1c2c) !important;
}
.ant-menu-submenu-popup
  > .ant-menu-vertical
  > .ant-menu-item
  > .ant-menu-title-content {
  color: var(--text-secondary, #d2d2d2);
  /* Subhead/Subhead 2/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.ant-menu-submenu-popup > .ant-menu-vertical > .ant-menu-item,
.ant-menu-submenu-popup > .ant-menu-vertical > .ant-menu-item:not(:last-child) {
  margin: 0px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 2px;
}

.ant-menu-submenu-popup
  > .ant-menu-vertical
  > .ant-menu-item:hover
  svg
  > circle {
  stroke-opacity: 1;
}

.ant-menu-submenu-popup
  > .ant-menu-vertical
  > .ant-menu-item-selected
  > svg
  > circle {
  stroke-opacity: 1;
}
.ant-menu-submenu-popup > .ant-menu-vertical > .ant-menu-item:hover {
  background: var(--surface-bg-2, #1c1c2c) !important;
}
.ant-menu-submenu-popup > .ant-menu-vertical > .ant-menu-item-selected {
  background: var(--surface-bg-2, #1c1c2c) !important;
}
.ant-menu-submenu-popup
  > .ant-menu-vertical
  > .ant-menu-item-selected
  > .ant-menu-title-content {
  color: var(--text-primary, #fff);
  /* Subhead/Subhead 2/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.ant-select-item-option-content {
  text-transform: capitalize !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.rc-virtual-list-holder-inner {
  background-color: #090917;
}

.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator {
  background: var(--primary, #e250e5) !important;
}

.bp4-control-indicator:focus {
  outline: none !important;
  outline-offset: 0px !important;
}
.bp4-control-indicator {
  outline: none !important;
  outline-offset: 0px !important;
}

/* div.bp4-popover2-content > div > div > div:nth-child(1) {
  width: 140px !important;
} */

div.bp4-popover2-transition-container.bp4-overlay-content.bp4-popover2-appear-done.bp4-popover2-enter-done
  > div
  > div.bp4-popover2-content
  > div
  > div:nth-child(7) {
  gap: 14px;
}

.bp4-slider {
  min-width: 140px !important;
}

.ant-tooltip-inner {
  background-color: var(--bg-on-blue, #141420) !important;
}
.ant-tooltip-arrow {
  display: none !important;
}

.writing-management-action {
  /* left: 230px !important;*/
  /*top: 336px !important;*/
  /*transform-origin: 146px -4px !important;*/
}

.writing-management-action > .ant-popover-content {
  /*transform: translate(3px, -13px) !important;*/
      transform: translate(1px, -8px) !important;

}

.writing-management-action > .ant-popover-content > .ant-popover-arrow {
  display: none;
}
.writing-management-action > .ant-popover-content > .ant-popover-inner {
  background: transparent;
}

.writing-management-action
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  padding: 0px;
}

.download-button-popover-top {
  top: 65px !important;
}

.download-button-popover > .ant-popover-content {
    border-radius: 8px;
    background: #141420;
    width: 263px;
}
